import { Carousel } from "primereact/carousel";
import logoImage from "../../assets/images/logo.svg";
import sliderImg from "../../assets/images/landings-slider-img.jpg";
import "../../assets/styles/login/login.scss";

const AuthLayout = ({ children }) => {
  return (
    <div>
      <div className="login-page">
        {/* Content left side */}
        <div className="login-page__left">
          <div className="logo-holder">
            <img src={logoImage} alt="logo" />
          </div>
          <div className="login-content">
            <div className="login-content__wrapper">{children}</div>
          </div>
        </div>
        {/* Coontent right side */}

        <div className="login-page__right">
          <div className="carousel-wrapper">
            <div className="p-carousel-item">
              <div className="slides-wrapper">
                <img src={sliderImg} alt="Slider" />
                <div className="carousel-caption">
                  <h5>PROFESSIONAL</h5>
                  <h2>PAINTING</h2>
                  <h4>WORK</h4>
                  <h5>THROUGHOUT</h5>
                  <h3>ZEALAND</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
